import store from '../../store'

import Highway from '@dogstudio/highway'
import { qs, qsa } from '../../utils'
import { gsap } from 'gsap'

export default class ServiceOut extends Highway.Transition {
  out({ from, trigger, done }) {
    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        window.scrollTo(0, 0)
        store.raf.setScroll(0)
        done()
      },
    })

    if (trigger == 'script') {
      const section = qs('.insights')
      const chars = qsa('.char-1', section)
      const posts = qsa('.insight-post', section)

      tl.to(chars, {
        duration: 1,
        y: '-110%',
        stagger: 0.05,
        ease: 'power3.inOut',
      })

      tl.to(
        posts,
        {
          duration: 1.4,
          x: '-80%',
          stagger: 0.12,
          autoAlpha: 0,
          ease: 'power3.out',
        },
        '-=0.9',
      )
    } else {
      const parent = trigger.parentNode
      const section = parent.parentNode
      const page = section.parentNode
      const button = qs('.button', section)
      const footer = qs('.section-footer .footer-content', page)
      const chars = qsa('.char-1', section)
      const bgColor = store.mode == 'light' ? '#cccccc' : '#292626'

      gsap.set(store.body, { background: bgColor })

      tl.to(footer, { duration: 0.6, y: '100%', ease: 'power2.out' }, 0)

      tl.to(
        chars,
        {
          duration: 1,
          y: '-110%',
          stagger: 0.05,
          ease: 'power3.inOut',
        },
        '-=0.6',
      )

      tl.to(button, { duration: 0.6, autoAlpha: 0, ease: 'power2.out' }, 0)
    }

    tl.fromTo(from, { autoAlpha: 1 }, { duration: 0.3, autoAlpha: 0 })

    tl.play()
  }

  in({ from, to, trigger, done }) {
    const mode = store.mode
    const blog = to.dataset.routerView == 'blog'
    const bgColor = mode == 'light' ? '#cccccc' : '#292626'
    const blogColor = mode == 'light' ? '#f6f6f6' : '#cccccc'

    const delay = blog ? 1000 : 100

    setTimeout(() => {
      from.remove()
      done()
    }, delay)
  }
}
