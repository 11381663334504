import gsap from 'gsap/gsap-core'
import { qs, qsa, bindAll, bounds } from '../utils'

export const Buttons = () => {
  const buttons = qsa('.button')

  buttons.forEach((button) => {
    button.addEventListener('mouseenter', buttonIn)
    button.addEventListener('mouseleave', buttonOut)
  })
}

const buttonIn = (el) => {
  const target = el.target
  const charsOut = qsa('.-out .char-0', target)
  const charsIn = qsa('.-in .char-0', target)

  if (charsOut.length === 0) return

  gsap.fromTo(
    charsOut,
    { y: '0%' },
    { duration: 0.5, y: '-101%', stagger: 0.015, ease: 'powe3.out' },
  )
  gsap.fromTo(
    charsIn,
    { y: '101%' },
    { duration: 0.5, y: '0%', stagger: 0.015, ease: 'powe3.out' },
  )
}

const buttonOut = (el) => {
  const target = el.target
  const charsOut = qsa('.-out .char-0', target)
  const charsIn = qsa('.-in .char-0', target)

  if (charsOut.length === 0) return

  gsap.fromTo(
    charsIn,
    { y: '0%' },
    { duration: 0.5, y: '101%', stagger: 0.015, ease: 'powe3.out' },
  )
  gsap.fromTo(
    charsOut,
    { y: '-101%' },
    { duration: 0.5, y: '0%', stagger: 0.015, ease: 'powe3.out' },
  )
}
