import store from '../store'
import { qs, qsa } from '../utils'
import { gsap } from 'gsap'

export const postoneScroll = () => {
  const hero = qs('.post.-one')
  const obj = {}

  if (!hero) return

  obj.el = hero
  obj.title = qs('.post-title', hero)
  obj.titleMask = qsa('.line-0', obj.title)
  obj.date = qs('.post-date', hero)
  obj.info = qs('.post-info', hero)
  obj.infoTitle = qs('.info-title', hero)
  obj.item = qsa('.topic-item', hero)
  obj.excerpt = qs('.post-excerpt', hero)
  obj.excerptMask = obj.excerpt ? qsa('.line-0', obj.excerpt) : null
  obj.button = qs('.button')
  obj.feature = qs('.post-description', hero)
  obj.featureTitle = obj.feature ? qsa('.featured-title', obj.feature) : null

  obj.tl = gsap.timeline({
    scrollTrigger: {
      trigger: obj.title,
      start: 'top 70%',
    },
  })

  obj.tl.from(obj.titleMask, {
    duration: 0.8,
    y: '101%',
    stagger: 0.1,
    ease: 'power3.out',
  })

  obj.tl.from(obj.date, { duration: 0.5, autoAlpha: 0, y: 40 }, '-=0.8')
  obj.tl.from(obj.infoTitle, { duration: 0.5, autoAlpha: 0, y: 20 }, '-=0.4')
  if (obj.item.length !== 0)
    obj.tl.from(
      obj.item,
      { duration: 0.5, autoAlpha: 0, y: 20, stagger: 0.05 },
      '-=0.4',
    )
  if (obj.excerpt)
    obj.tl.from(
      obj.excerptMask,
      { duration: 0.5, autoAlpha: 0, y: 20, stagger: 0.05 },
      '-=0.4',
    )
  if (obj.button)
    obj.tl.from(obj.button, { duration: 0.5, autoAlpha: 0, y: 20 }, '-=0.4')

  if (obj.feature && obj.featureTitle) {
    gsap.from(obj.featureTitle, {
      scrollTrigger: {
        trigger: obj.feature,
        start: 'top 70%',
      },
      duration: 0.6,
      y: 60,
      autoAlpha: 0,
      stagger: 0.25,
    })
  }
}

export const posttwoScroll = () => {
  const two = qs('.post.-two')
  const obj = {}

  if (!two) return

  obj.el = two
  obj.posts = qsa('.post-content', two)

  obj.posts.forEach((post, i) => {
    const img = qs('.post-img', post)
    const title = qsa('.post-title .word-0', post)
    const info = qs('.post-info', post)

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: post,
        start: 'top 70%',
      },
      delay: i * 0.1,
    })

    tl.from(img, { duration: 0.6, y: '80%', autoAlpha: 0 })
    tl.from(
      title,
      {
        duration: 1,
        y: '101%',
        stagger: {
          each: 0.01,
          ease: 'power3.inOut',
        },
      },
      '-=0.4',
    )
    tl.from(info, { duration: 0.8, y: 60, autoAlpha: 0 }, '-=0.4')
  })
}

export const postlistScroll = () => {
  const postlist = qs('.post.-list')
  const obj = {}

  if (!postlist) return

  obj.el = postlist

  obj.posts = qsa('.post-content', postlist)
  obj.topics = qs('.post-topics')
  obj.topicItem = qsa('.p1', obj.topics)

  obj.posts.forEach((post) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: post,
        start: 'top 80%',
      },
    })

    tl.from(post, { duration: 0.6, autoAlpha: 0, y: '80%' })
  })

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: obj.topics,
      start: 'top 80%',
    },
  })

  tl.from(obj.topicItem, { duration: 0.5, autoAlpha: 0, y: 20, stagger: 0.1 })
}

export const featureScroll = () => {
  const featured = qs('.featured')
  const obj = {}

  if (!featured) return

  obj.el = featured
  obj.title = qs('.featured-title')
  obj.words = qsa('.word-1', obj.el)
  obj.sectionTitle = qs('.section-title.t2', obj.el)
  obj.sectionTitle2 = qs('.section-title.p1', obj.el)
  obj.text = qs('.featured-txt', obj.el)
  obj.chars = obj.sectionTitle ? qsa('.char-0', obj.sectionTitle) : null
  obj.p = obj.text ? qsa('.p', obj.text) : null

  // Animation Large Title
  if (obj.title) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: obj.el,
        start: 'top 60%',
      },
      onComplete: () => {
        gsap.set(obj.title, { clearProps: 'all' })
        store.splits.reverseContent(obj.title)
      },
    })

    gsap.set(obj.title, { lineHeight: 0 })

    tl.from(obj.words, {
      duration: 0.6,
      y: '101%',
      stagger: {
        each: 0.01,
        ease: 'power3.inOut',
      },
    })
  }

  // Animation Char Title
  if (obj.chars) {
    gsap.from(obj.chars, {
      scrollTrigger: {
        trigger: obj.sectionTitle,
        scrub: true,
        start: 'top 70%',
        end: 'bottom 50%',
        once: true,
      },
      autoAlpha: 0,
      stagger: {
        each: 0.05,
        from: 'center',
      },
      ease: 'power3.out',
    })
  }

  if (obj.sectionTitle2) {
    gsap.from(obj.sectionTitle2, {
      scrollTrigger: {
        trigger: obj.sectionTitle2,
        start: 'top 60%',
      },
      duration: 0.5,
      y: 20,
      autoAlpha: 0,
    })
  }

  // Animation Text area
  if (obj.p) {
    gsap.from(obj.p, {
      scrollTrigger: {
        trigger: obj.sectionTitle,
        start: 'top 55%',
      },
      duration: 1,
      x: '100',
      autoAlpha: 0,
      stagger: 0.08,
      ease: 'power3.out',
    })
  }
}

export const subserviceScroll = () => {
  const subservices = qsa('.subservice')

  if (subservices.length == 0) return

  subservices.forEach((subservice) => {
    const service = {}
    service.el = subservice
    const title = qs('.subservice-title', subservice)
    const titleLines = qsa('.line-0', title)
    const description = qs('.subservice-description', subservice)
    const descriptionLines = qsa('.line-0', description)
    const p3 = qsa('p.p3', subservice)
    const p = qsa('.p', subservice)
    const button = qs('.button.-desktop', subservice)
    const button2 = qs('.button.-mobile', subservice)

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: service.el,
        start: 'top 50%',
      },
      onComplete: () => {
        gsap.set(title, { clearProps: 'all' })
        store.splits.reverseContent(title)
        store.splits.reverseContent(description)
      },
    })

    gsap.set(title, { lineHeight: 0 })

    if (titleLines.length !== 0)
      tl.from(titleLines, {
        duration: 0.8,
        y: '101%',
        stagger: 0.1,
        ease: 'power3.out',
      })

    if (descriptionLines.length !== 0)
      tl.from(
        descriptionLines,
        {
          duration: 0.8,
          y: 100,
          autoAlpha: 0,
          stagger: 0.05,
          ease: 'power3.out',
        },
        '-=0.6',
      )

    if (p3.length !== 0) {
      tl.from(
        p3,
        {
          duration: 1,
          y: 80,
          autoAlpha: 0,
          stagger: 0.1,
          ease: 'power3.inOut',
        },
        '-=0.6',
      )
    }

    tl.from(
      button,
      {
        duration: 0.5,
        autoAlpha: 0,
        y: 60,
        ease: 'power3.in',
      },
      '-=0.5',
    )

    tl.from(
      button2,
      {
        duration: 0.5,
        autoAlpha: 0,
        y: 60,
        ease: 'power3.in',
      },
      '-=0.5',
    )

    if (p.length !== 0) {
      tl.from(
        p,
        {
          duration: 0.8,
          y: 80,
          autoAlpha: 0,
          stagger: 0.15,
          ease: 'power3.out',
        },
        '-=0.8',
      )
    }
  })
}

export const valuesScroll = () => {
  const values = qs('.values')
  const obj = {}

  if (!values) return

  obj.el = values
  obj.items = qsa('.values-item', obj.el)
  obj.support = qs('.values-support', obj.el)
  obj.lines = obj.support ? qsa('.line-0', obj.support) : null

  obj.items.forEach((item) => {
    const line = qs('.line', item)
    const nr = qs('.item-nr', item)
    const title = qsa('.item-title', item)
    const description = qsa('.word-0', item)
    const features = qsa('.features-item', item)

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: item,
        start: 'top 70%',
      },
    })

    if (nr) tl.from(nr, { duration: 0.5, autoAlpha: 0, y: 20 })
    if (title)
      tl.from(
        title,
        { duration: 0.5, autoAlpha: 0, y: 20, stagger: 0.4 },
        '-=0.4',
      )
    if (description.length !== 0)
      tl.from(
        description,
        {
          duration: 0.6,
          y: '101%',
          autoAlpha: 0,
          stagger: {
            each: 0.01,
            ease: 'power3.inOut',
          },
        },
        '-=0.3',
      )

    if (line)
      tl.from(
        line,
        {
          duration: 1,
          scaleX: 0,
          transformOrigin: 'left',
          ease: 'power3.inOut',
        },
        '-=1.2',
      )
    if (features.length !== 0)
      tl.from(
        features,
        { duration: 0.8, autoAlpha: 0, y: 40, stagger: 0.1 },
        '-=0.5',
      )
  })

  if (obj.support) {
    gsap.from(obj.lines, {
      scrollTrigger: {
        trigger: obj.support,
        start: 'top 60%',
      },
      duration: 0.8,
      autoAlpha: 0,
      y: '101%',
      stagger: 0.1,
      ease: 'power3.out',
      onComplete: () => {
        store.splits.reverseContent(obj.support)
      },
    })
  }
}

export const addedScroll = () => {
  const added = qs('.added')
  const obj = {}

  if (!added) return

  obj.el = added
  obj.title = qs('.added-title', obj.el)
  obj.lines = qsa('.line-0', obj.title)
  obj.description = qs('.added-description', obj.el)
  obj.line = qsa('.line-0', obj.description)
  obj.cols = qsa('.added-col', obj.el)

  obj.tl = gsap.timeline({
    scrollTrigger: {
      trigger: obj.el,
      start: 'top 60%',
    },
    onComplete: () => {
      store.splits.reverseContent(obj.title)
      store.splits.reverseContent(obj.description)
    },
  })

  obj.tl.from(obj.lines, { duration: 0.6, y: '101%', stagger: 0.1 })
  obj.tl.from(obj.line, {
    duration: 0.6,
    y: '101%',
    autoAlpha: 0,
    stagger: 0.1,
  })
  obj.tl.from(
    obj.cols,
    { duration: 0.8, autoAlpha: 0, y: 60, stagger: 0.15 },
    '-=0.5',
  )
}

export const careerScroll = () => {
  const careers = qs('.career')
  const obj = {}

  if (!careers) return

  obj.el = careers
  obj.title = qsa('.career-title .line-0', obj.el)
  obj.crop = qs('.img-crop', obj.el)
  obj.img = qs('img', obj.crop)
  obj.description = qs('.career-description', obj.el)
  obj.lines = qsa('.line-0', obj.description)
  obj.gallery = qsa('.gallery-item', obj.el)
  obj.jobs = qsa('.job-item', obj.el)

  gsap.from(obj.title, {
    scrollTrigger: {
      trigger: obj.el,
      start: 'top 70%',
    },
    duration: 0.6,
    y: '101%',
    stagger: 0.1,
  })

  gsap.from(obj.img, {
    scrollTrigger: {
      trigger: obj.crop,
      scrub: true,
      start: 'top 70%',
    },
    scale: 1.2,
  })

  gsap.from(obj.lines, {
    scrollTrigger: {
      trigger: obj.description,
      start: 'top 60%',
    },
    duration: 0.6,
    y: '101%',
    autoAlpha: 0,
    stagger: 0.1,
    onComplete: () => {
      store.splits.reverseContent(obj.description)
    },
  })

  obj.gallery.forEach((item) => {
    const img = qs('.fullc', item)
    const crop = qs('.gallery-img', item)
    const title = qs('.gallery-title', item)
    const description = qs('.gallery-description', item)

    gsap.from(img, {
      scrollTrigger: {
        trigger: item,
        scrub: true,
        start: 'top bottom',
      },
      scale: 1.2,
    })

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: item,
        start: 'top 70%',
      },
    })

    if (title) {
      tl.from([title, crop, description], {
        duration: 0.8,
        y: 200,
        autoAlpha: 0,
        stagger: 0.2,
      })
    } else {
      tl.from(crop, { duration: 0.8, y: '80%', autoAlpha: 0 })
    }
  })

  obj.jobs.forEach((item) => {
    const line = qs('.line', item)
    const position = qs('.job-position', item)
    const experience = qs('.job-experience', item)
    const remote = qs('.job-remote', item)
    const application = qs('.job-aplication', item)

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: item,
        start: 'top 70%',
      },
    })

    tl.from(position, { duration: 0.5, y: 20, autoAlpha: 0 })
    tl.from(experience, { duration: 0.5, y: 20, autoAlpha: 0 }, '-=0.4')
    tl.from(remote, { duration: 0.5, y: 20, autoAlpha: 0 }, '-=0.3')
    tl.from(application, { duration: 0.5, y: 20, autoAlpha: 0 }, '-=0.2')
    tl.from(
      line,
      {
        duration: 0.8,
        scaleX: 0,
        transformOrigin: 'left',
        ease: 'power3.inOut',
      },
      '-=1',
    )
  })
}

export const blogcontentScroll = () => {
  const content = qs('.single-content')
  const obj = {}

  if (!content) return

  obj.el = content

  obj.textblock = qsa('.text-block', content)
  obj.imageblock = qsa('.image-block', content)

  obj.textblock.forEach((block) => {
    const title = qs('.block-title', block)
    const content = qs('div', block)

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: block,
        start: 'top 70%',
      },
    })

    tl.from(title, { duration: 0.5, autoAlpha: 0, y: 20 })
    tl.from(content, { duration: 0.6, autoAlpha: 0, y: 40 }, '-=0.4')
  })

  obj.imageblock.forEach((block) => {
    const img = qs('img', block)

    gsap.from(img, {
      scrollTrigger: {
        trigger: img,
        scrub: true,
        start: 'top 70%',
        end: `+=${store.vh * 2}`,
      },
      scale: 1.2,
    })
  })
}

export const formScroll = () => {
  const form = qs('.form')
  const obj = {}

  if (!form) return

  obj.el = form
  obj.title = qs('.form-title', obj.el)
  obj.line = qsa('.line', obj.el)
  obj.input = qsa('input', obj.el)
  obj.select = qs('.custom-select', obj.el)
  obj.text = qs('textarea', obj.el)

  obj.tl = gsap.timeline({
    scrollTrigger: {
      trigger: obj.el,
      start: 'top 70%',
    },
    onComplete: () => {
      gsap.set(obj.select, { clearProps: 'all' })
      gsap.set(obj.input, { clearProps: 'all' })
    },
  })

  if (obj.title) {
    obj.tl.from(obj.title, { duration: 0.5, y: 40, autoAlpha: 0 })
  }

  obj.tl.from(obj.line, {
    duration: 1,
    scaleX: 0,
    stagger: 0.1,
    ease: 'power3.inOut',
  })

  obj.tl.from(
    obj.input,
    {
      duration: 0.6,
      autoAlpha: 0,
      y: 40,
      rotate: 3,
      transformOrigin: 'left',
      stagger: 0.15,
      ease: 'power3.out',
    },
    '-=1.2',
  )

  if (obj.select) {
    obj.tl.from(
      obj.select,
      {
        duration: 0.6,
        autoAlpha: 0,
        y: 40,
        rotate: 3,
        transformOrigin: 'left',
        ease: 'power3.out',
      },
      '-=1.1',
    )
  }

  if (obj.text) {
    obj.tl.from(
      obj.text,
      {
        duration: 0.6,
        autoAlpha: 0,
        y: 40,
        rotate: 3,
        transformOrigin: 'left',
        ease: 'power3.out',
      },
      '-=0.9',
    )
  }
}

export const tableScroll = () => {
  const table = qs('.table')
  const obj = {}

  if (!table) return

  obj.el = table
  obj.items = qsa('.table-item', obj.el)

  obj.items.forEach((item) => {
    gsap.from(item, {
      scrollTrigger: {
        trigger: item,
        start: 'top 70%',
      },
      duration: 0.8,
      autoAlpha: 0,
      y: 40,
    })
  })
}

export const relatedScroll = () => {
  const related = qs('.related')
  const obj = {}

  if (!related) return

  obj.el = related
  obj.line = qs('.line', related)
  obj.title = qs('.related-title', related)
  obj.posts = qsa('.related-post', related)

  obj.tl = gsap.timeline({
    scrollTrigger: {
      trigger: obj.el,
      start: 'top 70%',
    },
  })

  obj.tl.from(obj.line, {
    duration: 1,
    scaleX: 0,
    stagger: 0.1,
    transformOrigin: 'left',
    ease: 'power3.inOut',
  })

  obj.tl.from(obj.title, { duration: 0.5, autoAlpha: 0, y: 20 }, '-=0.8')

  obj.posts.forEach((post, i) => {
    const img = qs('.post-img', post)
    const header = qs('.post-header', post)
    const info = qs('.post-info', post)

    obj.tl.from(img, { duration: 0.6, autoAlpha: 0, y: 40 }, '-=0.7')
    obj.tl.from(header, { duration: 0.6, autoAlpha: 0, y: 40 }, '-=0.5')
    obj.tl.from(info, { duration: 0.6, autoAlpha: 0, y: 40 }, '-=0.4')
  })
}

export const infoScroll = () => {
  const info = qs('.info')
  const obj = {}

  if (!info) return

  obj.el = info
  obj.line = qs('.line', info)
  obj.title = qs('.info-title', info)
  obj.words = qsa('.word-0', info)

  obj.tl = gsap.timeline({
    scrollTrigger: {
      trigger: obj.el,
      start: 'top 70%',
    },
  })

  if (obj.title) {
    obj.tl.from(obj.title, { duration: 0.5, autoAlpha: 0, y: 20, stagger: 0.4 })
  }

  if (obj.words.length !== 0) {
    obj.tl.from(
      obj.words,
      {
        duration: 0.6,
        y: '101%',
        autoAlpha: 0,
        stagger: {
          each: 0.01,
          ease: 'power3.inOut',
        },
      },
      '-=0.3',
    )
  }

  if (obj.line) {
    obj.tl.from(
      obj.line,
      {
        duration: 1,
        scaleX: 0,
        transformOrigin: 'left',
        ease: 'power3.inOut',
      },
      '-=1.2',
    )
  }
}

export const contactScroll = () => {
  const contact = qs('.contact')
  const obj = {}

  if (!contact) return

  obj.el = contact
  obj.title = qs('.contact-title', contact)
  obj.address = qsa('.line-0', contact)
  obj.contact = qsa('.contact-item', contact)

  obj.tl = gsap.timeline({
    scrollTrigger: {
      trigger: obj.el,
      start: 'top 70%',
    },
  })

  if (obj.title) {
    obj.tl.from(obj.title, { duration: 0.5, autoAlpha: 0, y: 20, stagger: 0.4 })
  }

  if (obj.address.length !== 0) {
    obj.tl.from(
      obj.address,
      {
        duration: 0.6,
        y: '101%',
        autoAlpha: 0,
        stagger: {
          each: 0.05,
          ease: 'power3.inOut',
        },
      },
      '-=0.3',
    )
  }

  if (obj.contact.length !== 0) {
    obj.tl.from(
      obj.contact,
      {
        duration: 0.6,
        y: '101%',
        autoAlpha: 0,
        stagger: {
          each: 0.05,
          ease: 'power3.inOut',
        },
      },
      '-=0.3',
    )
  }
}

export const footerScroll = () => {
  const footer = qs('.footer')
  const obj = {}

  if (!footer) return

  obj.el = footer
  obj.form = qs('.form-item', obj.el)
  obj.label = qs('.p1', obj.form)
  obj.input = qsa('input', obj.form)
  obj.line = qs('.line', obj.form)
  obj.sitemap = qs('.footer-sitemap', obj.el)
  obj.sitemapItems = qsa('.p1', obj.sitemap)
  obj.copyright = qs('.footer-copyright', obj.el)
  obj.copyrightItems = qsa('.p1', obj.copyright)

  obj.tl = gsap.timeline({
    scrollTrigger: {
      trigger: obj.el,
      start: 'top 70%',
    },
    onComplete: () => {
      gsap.set(obj.sitemapItems, { clearProps: 'opacity' })
      gsap.set(obj.copyrightItems, { clearProps: 'opacity' })
    },
  })

  obj.tl.fromTo(
    obj.label,
    { autoAlpha: 0, y: 20 },
    { duration: 0.5, autoAlpha: 1, y: 0 },
  )
  obj.tl.from(
    obj.input,
    { duration: 0.5, autoAlpha: 0, y: 20, stagger: 0.1 },
    '-=0.4',
  )

  obj.tl.from(
    obj.line,
    { duration: 0.5, scaleX: 0, transformOrigin: 'left', ease: 'power3.inOut' },
    '-=1',
  )

  obj.tl.fromTo(
    obj.sitemapItems,
    { autoAlpha: 0, y: 20 },
    { duration: 0.8, y: 0, autoAlpha: 1, stagger: 0.05 },
    '-=0.5',
  )
  obj.tl.from(
    obj.copyrightItems,
    { duration: 0.8, y: 20, autoAlpha: 0, stagger: 0.1 },
    '-=0.8',
  )
}
