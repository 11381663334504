import store from '../store'
import { Emitter } from '../events'
import { qs, qsa, bounds, bindAll } from '../utils'
import { gsap } from 'gsap'

export default class Sticky {
  constructor(obj) {
    bindAll(this, 'resize', 'run', 'transform')

    this.el = obj.el.parentNode
    this.sticky = obj.el

    this.state = {
      top: 0,
      bottom: 0,
      max: 0,
      min: 0,
      resize: false,
      animate: false,
    }

    this.resize()
  }

  run(e) {
    const { min, max } = this.state
    this.scroll = e.current

    if (this.state.resize) return

    if (this.scroll >= min && this.scroll <= max) {
      this.transform()
    }
  }

  transform() {
    if (store.sniff.isDevice) return

    gsap.set(this.sticky, {
      y: this.scroll - this.state.top - this.state.height + store.vh,
    })
  }

  on() {
    Emitter.on('tick', this.run)
    Emitter.on('GlobalResize', this.resize)
  }

  animateIn() {
    if (this.state.animate) return

    gsap.fromTo(
      this.sticky,
      { autoAlpha: 0 },
      { duration: 0.5, autoAlpha: 1, delay: 0.6 },
    )
    this.state.animate = true
  }

  animateOut() {
    if (!this.state.animate) return

    gsap.to(this.sticky, { duration: 0.5, autoAlpha: 0 })
    this.state.animate = false
  }

  resize() {
    this.state.resize = true
    gsap.set(this.sticky, { clearProps: 'all' })

    const state = this.state
    const min = bounds(this.el).top - 5
    const max = bounds(this.el).bottom
    const top = bounds(this.sticky).top
    const height = bounds(this.sticky).height
    const bottom = bounds(this.sticky).bottom

    state.min = this.scroll ? this.scroll + min : min
    state.max = this.scroll ? this.scroll + max : max
    state.top = this.scroll ? this.scroll + top : top
    state.bottom = this.scroll ? this.scroll + bottom : bottom
    state.height = height

    this.state.resize = false
  }
}
