import store from '../../store'

import Highway from '@dogstudio/highway'
import { qs, qsa } from '../../utils'
import { gsap } from 'gsap'

export default class Fade extends Highway.Transition {
  out({ from, done }) {
    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        window.scrollTo(0, 0)
        store.raf.setScroll(0)
        done()
      },
    })

    const hero = qs('.hero', store.page)
    const chars = hero ? qsa('.char-1', hero) : null
    const blog = store.page.dataset.routerView == 'blog'
    const bgColor = store.mode == 'light' ? '#cccccc' : '#292626'
    const blogColor = store.mode == 'light' ? '#f6f6f6' : '#cccccc'
    let delay = store.openMenu ? 0 : 1

    if (!store.openMenu) {
      store.raf.scrollTo(0, 0.5)

      setTimeout(() => {
        if (!blog) gsap.to(store.body, { duration: 0.8, background: bgColor })
      }, 500)

      if (chars) {
        delay = 0

        tl.to(chars, {
          duration: 1,
          y: '-110%',
          stagger: 0.05,
          ease: 'power3.inOut',
          delay: 1,
        })
      }
    } else {
      gsap.set(store.page, { autoAlpha: 0 })
      gsap.set(store.body, { background: bgColor })
    }

    tl.to(from, {
      duration: 0.5,
      autoAlpha: 0,
      ease: 'power4.out',
      delay: delay,
    })
    tl.play()
  }

  in({ from, to, done }) {
    const menu = store.openMenu

    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        if (menu) gsap.set(store.body, { clearProps: 'all' })
      },
    })

    const mode = store.mode
    const blog = to.dataset.routerView == 'blog'
    const bgColor = mode == 'light' ? '#cccccc' : '#292626'
    const blogColor = mode == 'light' ? '#f6f6f6' : '#cccccc'
    const delay = store.openMenu ? 100 : 700

    if (blog) {
      tl.to(store.body, { duration: 0.8, background: blogColor })
    } else {
      tl.to(store.body, { duration: 0.8, background: bgColor })
    }

    setTimeout(() => {
      from.remove()
      done()
    }, delay)

    tl.play()
  }
}
