import store from '../store'
import { Emitter, RafController } from '../events'
import { qs } from '../utils'

export default class Scrollbar {
  constructor() {
    this.el = null
    this.handle = null
    this.state = {
      clicked: false,
      scale: 0,
      current: 0,
    }

    this.height = 0

    this.init()
  }

  init() {
    this.create()
    this.setBounds()
    this.addEvents()
  }

  addEvents() {
    Emitter.on('tick', this.transform)
    Emitter.on('resize', this.resize)
    this.el.addEventListener('click', this.click)
    this.handle.addEventListener('mousedown', this.down)
    window.addEventListener('mousemove', this.move)
    window.addEventListener('mouseup', this.up)
  }

  setBounds() {
    // const scrollLimit = store.bounds.scroll
    // const scale =  scrollLimit / store.vh

    // this.height = parseInt(store.vh / scale)
    // this.state.scale = (scrollLimit )/  store.vh
    // this.handle.style.height =  `${this.height}px

    const scrollLimit = store.bounds.scroll
    const vh = store.vh

    this.state.scale = (scrollLimit + vh) / vh
    this.handle.style.height = `${vh / this.state.scale}px`
  }

  transform = ({ current, original }) => {
    const scroll = original / this.state.scale
    this.state.current = scroll

    this.handle.style.transform = `translate3d(0, ${this.state.current}px, 0)`
  }

  click = (e) => {
    this.calcScroll(e)
  }

  down = () => {
    this.state.clicked = true
    store.body.classList.add('is-dragging')
  }

  move = (e) => {
    if (!this.state.clicked) return
    this.calcScroll(e)
  }

  up = () => {
    this.state.clicked = false
    store.body.classList.remove('is-dragging')
  }

  resize = () => {
    this.setBounds()
  }

  calcScroll(e) {
    const delta = e.clientY * this.state.scale
    RafController.target = delta
    RafController.clampTarget()
  }

  create() {
    this.el = document.createElement('div')
    this.handle = document.createElement('div')
    this.el.classList.add('scrollbar', 'js-scrollbar')
    this.handle.classList.add('scrollbar__handle', 'js-scrollbar__handle')

    Object.assign(this.el.style, {
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      pointerEvents: 'all',
    })

    Object.assign(this.handle.style, {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      cursor: 'pointer',
    })

    store.page.appendChild(this.el)
    this.el.appendChild(this.handle)
  }

  update() {
    this.setBounds()
  }

  removeEvents() {
    this.el && this.el.removeEventListener('click', this.click)
    this.handle && this.handle.removeEventListener('mousedown', this.down)
    // window.addEventListener('mousemove', this.move)
    //window.addEventListener('mouseup', this.up)
  }

  destroy() {
    this.removeEvents()
  }
}
