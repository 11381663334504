import store from '../../store'
import Default from './default'
import { bindAll, qs, qsa, getid, bounds } from '../../utils'
import { ServiceIn } from '../../components/AnimateIn'
import {
  featureScroll,
  subserviceScroll,
  tableScroll,
  formScroll,
  footerScroll,
} from '../../components/ScrollAnimations'
import Model from '../../components/model'
import { gsap } from 'gsap'

class Service extends Default {
  constructor(opt = {}) {
    super(opt)

    this.slug = 'body-service'

    bindAll(this, 'scrollTo', 'updateTable')

    store.body.classList.add(this.slug)
    store.body.classList.remove('body-home')
    store.body.classList.remove('body-page')
    store.body.classList.remove('body-blog')
    store.body.classList.remove('body-event')
  }

  onEnter() {
    super.onEnter()
  }

  onEnterCompleted() {
    super.onEnterCompleted()
    this.addElements()
    this.addEvents()
    this.scrollToSections()

    this.getHash(window.location.hash)
  }

  addElements() {
    const select = qs('.table-select')
    this.tableItems = select ? qsa('.p1', select) : null
    this.tables = qsa('.table-singleservice')
  }

  addEvents() {
    this.tableItems &&
      this.tableItems.forEach((item) => {
        item.addEventListener('click', this.updateTable)
      })
  }

  removeEvents() {
    this.tableItems &&
      this.tableItems.forEach((item) => {
        item.removeEventListener('click', this.updateTable)
      })
  }

  getHash(url) {
    if (!url) return

    const id = url.substring(1)
    const section = getid(id)

    this.targets.forEach((target) => {
      if (target.el == section) {
        setTimeout(() => {
          store.raf.scrollTo(target.top)
        }, 2000)
      }
    })
  }

  scrollToSections() {
    this.targets = []
    this.linkTo = qsa('[data-link]')

    this.linkTo.forEach((link) => {
      const href = link.href
      const hash = href.split('#')[1]
      const el = getid(`${hash}`)
      const b = bounds(el)

      const obj = {
        el: el,
        href: href,
        hash: hash,
        top: b.top,
      }

      this.targets.push(obj)
      link.addEventListener('click', this.scrollTo)
    })
  }

  scrollTo(e) {
    e.preventDefault()
    const target = e.target
    const href = target.href

    this.targets.forEach((el) => {
      if (el.href == href) store.raf.scrollTo(el.top - 50)
    })
  }

  run(e) {
    super.run(e)
    this.mouse = e.mouse
  }

  updateTable(e) {
    const target = e.target
    const index = target.dataset.index
    const active = qs(`[data-table="${index}"]`)

    this.tableItems.forEach((item) => item.classList.remove('-active'))
    target.classList.add('-active')
    this.tables.forEach((table) =>
      gsap.to(table, {
        duration: 0.5,
        autoAlpha: 0,
        onComplete: () => {
          gsap.to(active, { duration: 0.5, autoAlpha: 1 })
        },
      }),
    )
  }

  animateIn() {
    super.animateIn()

    if (!store.loaded || !store.particlesReady) return

    const service = store.page.dataset.name
    const serviceIn = ServiceIn()

    store.three.models.forEach((model) => {
      if (model.name == service) {
        model.add()
      }
    })

    setTimeout(() => {
      serviceIn.play()
      this.animateOnScroll()
    }, 300)
  }

  animateOnScroll() {
    featureScroll()
    subserviceScroll()
    tableScroll()
    formScroll()
    footerScroll()
  }

  onResize() {
    super.onResize()
  }

  onLeave() {
    super.onLeave()
    this.removeEvents()
  }

  onLeaveCompleted() {
    super.onLeaveCompleted()
  }
}

export default Service
