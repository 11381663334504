import store from '../../store'
import Highway from '@dogstudio/highway'
import { Emitter } from '.././../events'

import {
  qs,
  qsa,
  bindAll,
  selectForm,
  growTextarea,
  formFocus,
} from '../../utils'
import SmoothScroll from '../../components/SmoothScroll'
import { HeaderIn } from '../../components/AnimateIn'
import { Buttons } from '../../components/HoverAnimations'
import Splits from '../../components/Splits'
import { gsap } from 'gsap/gsap-core'
import { ScrollTrigger } from '../../vendor/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

class Default extends Highway.Renderer {
  constructor(opt = {}) {
    super(opt)

    store.body.classList.remove('is-fixed')

    bindAll(
      this,
      'run',
      'onResize',
      'handleSubmit',
      'animateIn',
      'mouseMove',
      'openSelect',
      'closeSelect',
    )

    this.prealoder = qs('.preloader')

    this.state = {
      sticky: false,
      header: false,
      animate: false,
      select: false,
    }
  }

  onEnter() {
    store.page = this.wrap.lastElementChild
    store.header = qs('header', store.page)
  }

  onEnterCompleted() {
    ScrollTrigger.refresh()

    this.els()
    this.on()
    this.smooth()
    this.animateIn()
    this.addCanvas()
  }

  els() {
    this.header = qs('header')
    this.forms = qsa('form')
    this.success = qsa('.form-success')
    this.isHome = qs('.is-home')
    this.isBlog = qs('.is-blog') || qs('.is-post')

    // Custom Select
    this.customSelect = qs('.custom-select')
    if (this.customSelect) {
      this.selectPanel = qs('.select-panel', this.customSelect)
      this.selectDefault = qs('.option-default', this.customSelect)
      this.selectOptions = qsa('.option-value', this.customSelect)
      this.select = qs('.-select')
    }
  }

  on() {
    Emitter.on('tick', this.run)
    Emitter.on('GlobalResize', this.onResize)
    Emitter.on('fontLoaded', this.animateIn)
    Emitter.on('ModelsLoaded', this.animateIn)

    if (this.customSelect) {
      this.selectDefault.addEventListener('click', this.openSelect)

      this.selectOptions.forEach((option) => {
        if (!option) return
        option.addEventListener('click', (e) => {
          this.closeSelect()
          this.updateSelect(e)
        })
      })
    }

    window.addEventListener('mousemove', this.mouseMove)
    window.addEventListener('click', () => {
      if (this.state.select) this.closeSelect()
    })

    this.forms.forEach((form) => {
      form.addEventListener('submit', (e) => {
        this.handleSubmit(e, form)
      })
    })

    selectForm()
    growTextarea()
    formFocus()

    const hover = Buttons()
  }

  off() {
    Emitter.off('tick', this.run)
    Emitter.off('GlobalResize', this.onResize)
    window.removeEventListener('mousemove', this.mouseMove)
  }

  run(e) {
    const { diff } = e
    this.direction = diff > 0 ? 'down' : 'up'
    this.mouse = e.mouse
    this.scroll = e.current

    if (
      this.scroll > 100 &&
      this.direction == 'down' &&
      !this.state.header &&
      !store.sniff.isDevice &&
      !this.isHome
    ) {
      if (this.state.animate) return

      this.state.animate = true

      gsap.to(this.header, {
        duration: 0.6,
        y: '-100%',
        autoAlpha: 0,
        ease: 'power3.out',
        onComplete: () => {
          this.state.animate = false
        },
      })
      this.state.header = true
    }

    if (this.direction == 'up' && this.state.header) {
      if (this.state.animate) return

      this.state.animate = true

      gsap.to(this.header, {
        y: '0%',
        autoAlpha: 1,
        onComplete: () => {
          this.state.animate = false
        },
      })
      this.state.header = false
    }

    if (!store.three.scene) return

    store.three.renderer &&
      store.three.renderer.render(store.three.scene, store.three.camera)

    store.three.models.forEach((model) => {
      if (model.isActive) {
        model.group.children.forEach((child) => {
          if (child.material.uniforms) {
            child.material.uniforms.uTime.value =
              store.three.clock.getElapsedTime()
          }
        })
      }
    })
  }

  addCanvas() {
    const blog = qs('.is-blog')

    if (blog) return

    const hero = qs('.-hero-page')
    const container = hero ? hero : store.page

    container.appendChild(store.three.renderer.domElement)
    store.three.renderer.domElement.style.top = '0'
    store.three.renderer.domElement.style.position = 'absolute'
    store.three.renderer.domElement.style.pointerEvents = 'none'

    if (this.isHome) {
      store.three.renderer.domElement.style.zIndex = -1
      store.three.renderer.domElement.style.position = 'fixed'
    }
  }

  mouseMove() {
    if (!this.mouse) return

    const x = this.mouse.x
    const y = this.mouse.y
    const xrange = gsap.utils.mapRange(0, store.vw, 0.2, -0.2, x)
    const yrange = gsap.utils.mapRange(0, store.vh, 0.2, -0.2, y)

    gsap.to(store.three.scene.rotation, {
      y: xrange,
      x: yrange,
    })
  }

  smooth() {
    const smooth = qsa('[data-smooth-item]', store.page)
    store.scroll.setScrollBounds()
    SmoothScroll.init(smooth)
  }

  animateIn() {
    if (!store.loaded || !store.particlesReady) return

    if (store.preloader) {
      gsap.set('.preloader svg', { autoAlpha: 0 })
      gsap.to(this.prealoder, {
        duration: 1.2,
        y: '100%',
        delay: 0.3,
        ease: 'power3.inOut',
      })
    }

    store.preloader = false
    store.splits = new Splits()

    setTimeout(() => {
      const headerIn = HeaderIn()
      headerIn.play()
      store.flags.locked = false
      gsap.set(store.body, { clearProps: 'background' })
    }, 500)
  }

  openSelect() {
    if (this.state.select) return
    const panel = this.selectPanel
    gsap.to(panel, {
      duration: 0.5,
      autoAlpha: 1,
      ease: 'power3.out',
      onComplete: () => {
        this.state.select = true
      },
    })
  }

  closeSelect() {
    if (!this.state.select) return
    const panel = this.selectPanel
    gsap.to(panel, {
      duration: 0.3,
      autoAlpha: 0,
      ease: 'power3.out',
      onComplete: () => {
        this.state.select = false
      },
    })
  }

  updateSelect(e) {
    const target = e.target
    const p = qs('p', target)
    const value = target.dataset.value
    const title = p.innerHTML
    const select = qs('select', this.select)

    this.customSelect.classList.add('-active')
    this.selectOptions.forEach((option) => option.classList.remove('-active'))
    target.classList.add('-active')
    this.selectDefault.innerHTML = title

    this.selectOptions.forEach((option, i) => {
      const data = option.dataset.value
      if (data == value) select.selectedIndex = i + 1
    })
  }

  resetSelect() {
    const select = qs('select', this.select)

    select.selectedIndex = 0
    this.customSelect.classList.remove('-active')
    this.selectDefault.innerHTML = 'Select a service'
  }

  handleSubmit(e, form) {
    e.preventDefault()

    const container = form.parentNode
    const formData = new FormData(form)
    const success = qs('.form-success', container)

    fetch(form.getAttribute('action'), {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        if (success) {
          const button = qs('.button', success)

          gsap.to(success, { duration: 0.5, autoAlpha: 1 })

          if (button) {
            button.addEventListener('click', () => {
              gsap.to(success, { duration: 0.5, autoAlpha: 0 })
              form.reset()
              this.resetSelect()
            })
          } else {
            gsap.to(success, { duration: 0.5, autoAlpha: 0, delay: 3.2 })
            form.reset()
          }
        } else {
          form.reset()
          this.customSelect && this.resetSelect()
        }
      })
      .catch((error) => console.log(error))
  }

  onResize() {
    store.three.models.forEach((model) => model.update())
  }

  removeModels() {
    store.three.models.forEach((model) => {
      if (model.isActive) {
        model.remove()
      }
    })
  }

  onLeave() {
    if (this.isHome || store.openMenu) {
      this.removeModels()
    } else {
      setTimeout(() => {
        if (this.isBlog) return
        this.removeModels()
      }, 600)
    }
  }

  onLeaveCompleted() {
    this.off()
    SmoothScroll.off()
    store.flags.locked = true
    ScrollTrigger.getAll().forEach((inst) => inst.kill(true))
  }
}

export default Default
