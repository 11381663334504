import store from '../store'
import { Emitter } from '../events'
import { qs, qsa } from '../utils'
import { gsap } from 'gsap'

export const HeaderIn = () => {
  const header = qs('header')
  const burger = qs('header .burger')

  const tl = gsap.timeline({
    paused: true,
  })

  tl.to(header, { duration: 0.6, y: '0%', autoAlpha: 1, ease: 'power3.out' })
  return tl
}

export const HomeIn = () => {
  const title = qs('.hero-title')
  const lines = qsa('.line-0', title)
  const description = qs('.hero-description')
  const scroll = qs('.scroll')

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      gsap.set(description, { clearProps: 'all' })
      gsap.set(scroll, { clearProps: 'all' })
      Emitter.emit('animation-in')
    },
  })

  tl.to(store.page, { duration: 0.1, autoAlpha: 1 })

  lines.forEach((el, i) => {
    const chars = qsa('.char-1', el)
    const time = i > 0 ? '-=1' : 0

    tl.from(
      chars,
      { duration: 1, y: '101%', stagger: 0.05, ease: 'power3.inOut' },
      time,
    )
  })

  tl.from(description, { duration: 0.5, autoAlpha: 0, y: '40' }, '-=0.8')
  tl.from(scroll, { duration: 0.5, autoAlpha: 0, y: '20' }, '-=0.5')

  return tl
}

export const ServiceIn = () => {
  const hero = qs('.hero')
  const title = qs('.hero-title')
  const lines = qsa('.line-0', title)
  const p2 = qsa('.p2', hero)

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      Emitter.emit('animation-in')
    },
  })

  tl.to(store.page, { duration: 0.1, autoAlpha: 1 })

  lines.forEach((el, i) => {
    const chars = qsa('.char-1', el)
    const time = i > 0 ? '-=1' : 0

    tl.from(
      chars,
      { duration: 1, y: '110%', stagger: 0.05, ease: 'power3.inOut' },
      time,
    )
  })

  tl.from(
    p2,
    { duration: 0.8, y: 20, autoAlpha: 0, stagger: 0.08, ease: 'power3.out' },
    '-=0.8',
  )

  return tl
}

export const PageIn = () => {
  const title = qs('.hero-title')
  const lines = qsa('.line-0', title)
  const footer = qs('.hero-footer')
  const items = qsa('.line-0', footer)
  const links = qsa('.hero-info .p2', footer)

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      Emitter.emit('animation-in')
    },
  })

  tl.to(store.page, { duration: 0.1, autoAlpha: 1 })

  lines.forEach((el, i) => {
    const chars = qsa('.char-1', el)
    const time = i > 0 ? '-=1' : 0

    tl.from(
      chars,
      { duration: 1, y: '101%', stagger: 0.05, ease: 'power3.inOut' },
      time,
    )
  })

  tl.from(
    items,
    { duration: 0.8, y: 20, autoAlpha: 0, stagger: 0.1, ease: 'power3.out' },
    '-=0.8',
  )
  tl.from(
    links,
    { duration: 0.8, y: 20, autoAlpha: 0, stagger: 0.1, ease: 'power3.out' },
    '-=0.8',
  )

  return tl
}

export const BlogIn = () => {
  const mask1 = qs('.mask.-first')
  const mask2 = qs('.mask.-second')
  const title = qs('.hero-title')
  const lines = title ? qsa('.line-0', title) : null

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      Emitter.emit('animation-in')
    },
  })

  tl.to(store.page, { duration: 0.1, autoAlpha: 1 })

  if (mask1) {
    tl.from(mask1, {
      duration: 1.2,
      transformOrigin: 'top',
      y: '-101%',
      ease: 'power3.inOut',
    })
    tl.from(
      mask2,
      {
        duration: 1.2,
        transformOrigin: 'top',
        y: '101%',
        ease: 'power3.inOut',
      },
      '-=1.2',
    )
  }

  if (title) {
    lines.forEach((el, i) => {
      const chars = qsa('.char-1', el)
      const time = i > 0 ? '-=1' : 0

      tl.from(
        chars,
        { duration: 1, y: '110%', stagger: 0.05, ease: 'power3.inOut' },
        time,
      )
    })
  }

  return tl
}
