import store from '../../store'
import Default from './default'
import { bindAll, qs, qsa, getid, bounds } from '../../utils'
import { PageIn } from '../../components/AnimateIn'

import {
  featureScroll,
  formScroll,
  valuesScroll,
  addedScroll,
  infoScroll,
  footerScroll,
  contactScroll,
  careerScroll,
} from '../../components/ScrollAnimations'

class Page extends Default {
  constructor(opt = {}) {
    super(opt)

    this.slug = 'body-page'

    store.body.classList.add(this.slug)
    store.body.classList.remove('body-home')
    store.body.classList.remove('body-service')
    store.body.classList.remove('body-blog')
    store.body.classList.remove('body-event')
  }

  onEnter() {
    super.onEnter()
  }

  onEnterCompleted() {
    super.onEnterCompleted()
    this.addElements()
    this.addEvents()
  }

  addElements() {}

  addEvents() {}

  removeEvents() {}

  animateIn() {
    super.animateIn()

    if (!store.loaded || !store.particlesReady) return

    const pageIn = PageIn()
    const page = store.page.dataset.name

    store.three.models.forEach((model) => {
      if (model.name == page) {
        model.add()
      }
    })

    setTimeout(() => {
      pageIn.play()
      this.animateOnScroll()
    }, 300)
  }

  animateOnScroll() {
    featureScroll()
    valuesScroll()
    addedScroll()
    careerScroll()
    infoScroll()
    formScroll()
    contactScroll()
    footerScroll()
  }

  onLeave() {
    super.onLeave()
    this.removeEvents()
  }

  onLeaveCompleted() {
    super.onLeaveCompleted()
  }
}

export default Page
