import store from '../store'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js'
import random from 'random'
import { vertexShader } from '../glsl/vertexShader'
import { fragmentShader } from '../glsl/fragmentShader'

const vertex = `

varying vec2 vUv;
uniform float uTime;
uniform float uScale;

  void main() {
  vUv =  uv;
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
  gl_Position = projectionMatrix * mvPosition;
  //gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }
`

const fragment = `

varying vec2 vUv;
uniform float uTime;
uniform float uScale;
uniform vec2 resolution;

vec3 rgb2color(vec3 rgb) {
  float max = 255.;
  vec3 color = vec3( rgb.x / max, rgb.y / max, rgb.z / max);
  return color;
}

  void main() {
    vec2 uv = vUv;
    //vec2 uv = (vUv.xy * resolution * 2.0 - resolution.xy) / min(resolution.x, resolution.y);
    vec3 lightGrey = rgb2color(vec3(65.0, 65.0, 65.0));
    vec3 darkGrey = rgb2color(vec3(10.0, 10.0, 10.0));
    vec3 color = mix( darkGrey, lightGrey, uv.y);
    gl_FragColor = vec4(color, uScale);
  }
`

import { gsap } from 'gsap'

class Model {
  constructor(obj) {
    this.name = obj.name
    this.scene = obj.scene
    this.particles = obj.particles
    this.placeOnLoad = obj.onLoad
    this.files = obj.files
    this.range = obj.range
    this.density = obj.density
    this.size = obj.size
    this.cubeSize = obj.cubeSize || 0.5
    this.direction = obj.direction
    this.distribution = obj.distribution || 'vertices'
    this.isReady = false
    this.isActive = false
    this.loader = new GLTFLoader()
    this.dracoLoader = new DRACOLoader()
    this.dracoLoader.setDecoderPath('/assets/static/draco/')
    this.loader.setDRACOLoader(this.dracoLoader)

    this.init()
    this.update()
  }

  init() {
    this.files.forEach((file) => {
      this.group = new THREE.Group()
      this.geometry = ''

      this.loader.load(file, (res) => {
        const geo = new THREE.SphereGeometry(1.4, 32, 32)
        const shaderMaterial = new THREE.ShaderMaterial({
          vertexShader: vertex,
          fragmentShader: fragment,
          transparent: true,
          uniforms: {
            uTime: { value: 0 },
            uScale: { value: 0 },
          },
        })
        const mat = new THREE.MeshBasicMaterial({
          color: 0x000000,
        })
        const sphere = new THREE.Mesh(geo, shaderMaterial)

        if (this.name == 'world') this.group.add(sphere)

        /*------------------------------
        Original Mesh
        ------------------------------*/
        let mesh = res.scene.children[0]

        /*------------------------------
        Material Mesh
        ------------------------------*/
        let material = new THREE.MeshBasicMaterial({
          color: 'red',
          wireframe: true,
        })

        /*------------------------------
        Geometry Mesh
        ------------------------------*/
        let geometry = mesh.geometry

        /*------------------------------
        Particles Material
        ------------------------------*/

        this.particlesMaterial = new THREE.ShaderMaterial({
          vertexShader: vertexShader,
          fragmentShader: fragmentShader,
          transparent: true,
          uniforms: {
            uTime: { value: 0 },
            uScale: { value: 0 },
          },
        })

        /*------------------------------
        Particles Geometry
        ------------------------------*/

        let sampler = new MeshSurfaceSampler(mesh)
          .setWeightAttribute('color')
          .build()
        let numParticles = this.particles
        let meshParticles = parseInt(numParticles / this.files.length)

        const particlesPosition = new Float32Array(meshParticles * 4)
        const particlesCenter = new Float32Array(meshParticles * 4)
        const colors = new Float32Array(meshParticles * 3)
        const size = new Float32Array(meshParticles * 3)

        for (let i = 0; i < meshParticles; i += 3) {
          const newPosition = new THREE.Vector3()
          const coin = Math.random()

          let x = geometry.attributes.position.array[i]
          let y = geometry.attributes.position.array[i + 1]
          let z = geometry.attributes.position.array[i + 2]

          newPosition.x = x
          newPosition.y = y
          newPosition.z = z

          if (this.direction == 'y') {
            if (
              newPosition.y < this.range[0] ||
              newPosition.y > this.range[1]
            ) {
              particlesCenter.set(
                [newPosition.x, newPosition.y, newPosition.z, this.cubeSize],
                i * 4,
              )
            } else {
              if (coin > this.density) {
                particlesCenter.set(
                  [
                    newPosition.x * 1,
                    newPosition.y * 1,
                    newPosition.z * 1,
                    random.float(this.size[0], this.size[1]),
                  ],
                  i * 4,
                )
              }
            }
          } else {
            if (
              newPosition.x < this.range[0] ||
              newPosition.x > this.range[1]
            ) {
              particlesCenter.set(
                [newPosition.x, newPosition.y, newPosition.z, this.cubeSize],
                i * 4,
              )
            } else {
              if (coin > this.density) {
                particlesCenter.set(
                  [
                    newPosition.x * 1,
                    newPosition.y * 1,
                    newPosition.z * 1,
                    random.float(this.size[0], this.size[1]),
                  ],
                  i * 4,
                )
              }
            }
          }

          // Pick a color
          if (coin > 0.75) {
            colors[i] = Math.random()
          }

          size[i] = random.float(0.1, 1)

          const samplePosition = new THREE.Vector3()
          sampler.sample(samplePosition)

          particlesPosition.set(
            [
              samplePosition.x,
              samplePosition.y,
              samplePosition.z,
              this.cubeSize,
            ],
            i * 4,
          )
        }

        const baseGeometry = new THREE.BoxGeometry(0.12, 0.12, 0.12)
        const instancedGeometry = new THREE.InstancedBufferGeometry().copy(
          baseGeometry,
        )
        instancedGeometry.instanceCount = meshParticles

        // instancedGeometry.setAttribute(
        //   'aPosition',
        //   new THREE.InstancedBufferAttribute(particlesPosition, 3),
        // )

        const allParticles =
          this.distribution == 'vertices' ? particlesCenter : particlesPosition

        instancedGeometry.setAttribute(
          'aCenter',
          new THREE.InstancedBufferAttribute(allParticles, 4),
        )
        instancedGeometry.setAttribute(
          'aColor',
          new THREE.InstancedBufferAttribute(colors, 3),
        )

        instancedGeometry.setAttribute(
          'aSize',
          new THREE.InstancedBufferAttribute(size, 3),
        )

        /*------------------------------
        Particles
        ------------------------------*/
        let model = new THREE.InstancedMesh(
          instancedGeometry,
          this.particlesMaterial,
          meshParticles,
        )

        this.group.add(model)
        this.group.name = this.name

        /*------------------------------
        On Load
        ------------------------------*/

        this.isReady = true

        if (this.placeOnLoad) {
          this.add()
        }
      })
    })
  }

  update() {
    let val = store.vw / 1024
    val = val > 1 ? 1 : val < 0.5 ? 0.5 : val
    this.group.scale.set(val, val, val)
  }

  add() {
    this.isActive = true
    this.scene.add(this.group)
    this.group.children.forEach((el) => {
      if (!el.material.uniforms) return

      gsap.to(el.material.uniforms.uScale, {
        duration: 1.2,
        value: 1,
        delay: 1,
      })
    })

    gsap.fromTo(
      this.group.rotation,
      { y: -2 },
      { duration: 1, y: 0, x: 0, delay: 1, ease: 'power2.out' },
    )
  }

  remove() {
    this.group.children.forEach((el) => {
      if (!el.material.uniforms) return

      gsap.to(el.material.uniforms.uScale, {
        duration: 1.2,
        value: 0,
        onComplete: () => {
          this.scene.remove(this.group)
          this.isActive = false
        },
      })
    })

    gsap.to(this.group.rotation, { duration: 1, y: 2, ease: 'power2.out' })
  }
}

export default Model
