import store from '../store'
import * as THREE from 'three'
import { OrbitControls } from '../vendor/jsm/controls/OrbitControls.js'
import Model from './model'
import { Emitter } from '../events'

export default class ThreeSetup {
  constructor() {
    this.isReady = false
    this.total = 0

    store.three = {
      render: null,
      camera: null,
      scene: null,
      models: [],
      clock: null,
    }

    this.init()
  }

  loadModels() {
    if (!store.three.scene) return

    /*------------------------------
    Models
    ------------------------------*/

    const world = new Model({
      name: 'world',
      files: ['/assets/static/models/continents.glb'],
      scene: store.three.scene,
      particles: 10000,
      range: [-0.2, 0.3],
      density: 0.5,
      size: [0.3, 0.6],
      cubeSize: 0.4,
      distribution: 'surface',
    })

    const cloud = new Model({
      name: 'cloudwatch',
      files: ['/assets/static/models/cloud.glb'],
      scene: store.three.scene,
      particles: 40000,
      range: [-0.5, 0.5],
      density: 0.5,
      size: [0.25, 0.7],
      direction: 'x',
      cubeSize: 0.7,
      distribution: 'vertices',
    })

    const bug = new Model({
      name: 'threatwatch',
      files: ['/assets/static/models/bug.glb'],
      scene: store.three.scene,
      particles: 70000,
      range: [-0.5, 0.5],
      density: 0.5,
      size: [0.25, 0.6],
      direction: 'x',
      cubeSize: 0.4,
      distribution: 'vertices',
    })

    const lock = new Model({
      name: 'cybersecurity',
      files: ['/assets/static/models/lock.glb'],
      particles: 40000,
      scene: store.three.scene,
      range: [-0.25, 0.25],
      density: 0.25,
      direction: 'y',
      size: [0.3, 0.6],
      cubeSize: 0.8,
    })

    const ball = new Model({
      name: 'basketball',
      files: ['/assets/static/models/basketball.glb'],
      scene: store.three.scene,
      particles: 80000,
      range: [0.1, 0.4],
      density: 0,
      direction: 'y',
      size: [0.15, 0.5],
      cubeSize: 0.25,
      distribution: 'vertices',
    })

    const baseball = new Model({
      name: 'baseball',
      files: ['/assets/static/models/baseball.glb'],
      scene: store.three.scene,
      particles: 80000,
      range: [0.1, 0.4],
      density: 0,
      direction: 'y',
      size: [0.15, 0.5],
      cubeSize: 0.25,
      distribution: 'vertices',
    })

    const horse = new Model({
      name: 'horse',
      files: ['/assets/static/models/horse.glb'],
      scene: store.three.scene,
      particles: 8000,
      range: [0.1, 0.4],
      density: 0,
      direction: 'y',
      size: [0.15, 0.5],
      cubeSize: 0.25,
      distribution: 'vertices',
    })

    const football = new Model({
      name: 'football',
      files: ['/assets/static/models/football.glb'],
      scene: store.three.scene,
      particles: 80000,
      range: [0.1, 0.4],
      density: 0,
      direction: 'x',
      size: [0.15, 0.5],
      cubeSize: 0.35,
      distribution: 'vertices',
    })

    const golf = new Model({
      name: 'golf',
      files: ['/assets/static/models/golf.glb'],
      scene: store.three.scene,
      particles: 1000000,
      range: [0.1, 0.4],
      density: 0,
      direction: 'x',
      size: [0.15, 0.5],
      cubeSize: 0.35,
      distribution: 'vertices',
    })

    const key = new Model({
      name: 'partner',
      files: ['/assets/static/models/keys.glb'],
      particles: 180000,
      scene: store.three.scene,
      range: [-0.25, 0.25],
      density: 0.5,
      size: [0.15, 0.4],
      direction: 'y',
      cubeSize: 0.4,
    })

    const badge = new Model({
      name: 'company',
      files: ['/assets/static/models/badge.glb'],
      particles: 40000,
      scene: store.three.scene,
      range: [0.4, 0.6],
      density: 0.5,
      size: [0.1, 0.4],
      direction: 'y',
      cubeSize: 0.4,
    })

    const bubble = new Model({
      name: 'contact',
      files: ['/assets/static/models/bubble.glb'],
      particles: 40000,
      scene: store.three.scene,
      range: [0, 1],
      density: 0.4,
      size: [0.3, 0.6],
    })

    const wine = new Model({
      name: 'wine',
      files: ['/assets/static/models/wine.glb'],
      particles: 20000,
      scene: store.three.scene,
      range: [-0.5, 0.5],
      density: 0.5,
      size: [0.25, 0.5],
      cubeSize: 0.3,
    })

    store.three.models[0] = world
    store.three.models[1] = bug
    store.three.models[2] = cloud
    store.three.models[3] = lock
    store.three.models[4] = ball
    store.three.models[5] = key
    store.three.models[6] = badge
    store.three.models[7] = bubble
    store.three.models[8] = wine
    store.three.models[9] = football
    store.three.models[10] = horse
    store.three.models[11] = baseball
    store.three.models[12] = golf
    this.total = store.three.models.length
  }

  checkModels() {
    const check = []

    store.three.models.forEach((model) => {
      if (model.isReady) check.push(true)
    })

    if (check.length !== this.total) {
      setTimeout(() => {
        this.checkModels()
      }, 500)
    } else {
      store.particlesReady = true
      Emitter.emit('ModelsLoaded')
    }
  }

  setup() {
    /*------------------------------
    Renderer
    ------------------------------*/

    store.three.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    })

    store.three.renderer.setSize(store.vw, store.vh)

    /*------------------------------
    Scene & Camera
    ------------------------------*/
    store.three.scene = new THREE.Scene()
    store.three.camera = new THREE.PerspectiveCamera(
      50,
      store.vw / store.vh,
      0.1,
      100,
    )
    store.three.camera.position.z = 5
    store.three.camera.position.y = 1

    /*------------------------------
    Test Mesh
    ------------------------------*/

    const controls = new OrbitControls(
      store.three.camera,
      store.three.renderer.domElement,
    )
    controls.enabled = false

    /*------------------------------
    Clock
    ------------------------------*/
    store.three.clock = new THREE.Clock()
  }

  onResize() {
    if (!store.three.scene) return

    store.three.camera.aspect = store.vw / store.vh
    store.three.camera.updateProjectionMatrix()
    store.three.renderer.setSize(store.vw, store.vh)
  }

  init() {
    this.setup()
    this.loadModels()
    this.checkModels()
    this.onResize()
  }
}
