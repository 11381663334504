import store from '../store'

import Emitter from './Emitter'
import { Sniffer, bindAll, lerp, formFocus, formOut } from '../utils'
import { gsap } from 'gsap'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default class RafController {
  constructor() {
    bindAll(this, 'onScroll', 'onMove', 'onRaf')

    this.target = 0
    this.original = 0
    this.current = 0
    this.currentRounded = 0
    this.mouse = {
      x: 0,
      y: 0,
      target: null,
    }
    this.ease = 0.115

    this.return = false
  }

  onRaf() {
    this.current = lerp(this.current, this.target, this.ease)
    this.currentRounded = Math.round(this.current * 100) / 100
    this.diff = (this.target - this.current) * 0.005

    ScrollTrigger.update()

    Emitter.emit('tick', {
      target: this.target,
      current: this.getSmooth(),
      mouse: this.mouse,
      original: this.original,
      diff: this.diff,
    })
  }

  clampTarget() {
    this.target = Math.min(Math.max(this.target, 0), store.bounds.scroll)
    this.original = Math.min(Math.max(this.target, 0), store.bounds.scroll)
  }

  onScroll({ y }) {
    this.target += y
    this.clampTarget()
  }

  getSmooth() {
    if (store.flags.smooth) {
      return this.currentRounded
    } else {
      return window.pageYOffset
    }
  }

  scrollTo(offset, duration) {
    if (store.flags.smooth) {
      gsap.to(this, {
        target: offset,
        original: offset,
        duration: duration || 1,
        ease: 'expo.inOut',
      })
    } else {
      window.scrollTo({
        top: offset,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  setScroll(offset) {
    if (store.flags.smooth) {
      gsap.set(this, {
        target: offset,
        current: offset,
        currentRounded: offset,
      })
      gsap.to(this, {
        original: offset,
        duration: 1,
        ease: 'expo.inOut',
      })
    } else {
      window.scrollTo({
        top: offset,
        left: 0,
      })
    }
  }

  onMove({ x, y, target }) {
    this.mouse.x = x
    this.mouse.y = y
    this.mouse.target = target
  }

  setScrollTrigger() {
    ScrollTrigger.defaults({
      scroller: store.body,
    })

    ScrollTrigger.scrollerProxy(store.body, {
      scrollTop: () => {
        return this.getSmooth()
      },
      getBoundingClientRect() {
        return { top: 0, left: 0, width: store.vw, height: store.vh }
      },
    })
  }

  on() {
    gsap.ticker.add(this.onRaf)
    Emitter.on('scroll', this.onScroll)
    Emitter.on('mousemove', this.onMove)
  }
}
