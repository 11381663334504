import store from '../../store'
import Default from './default'
import { HomeIn } from '../../components/AnimateIn'
import GSlider from '../../components/GSlider'
import Sticky from '../../components/Sticky'
import Model from '../../components/model'
import { footerScroll } from '../../components/ScrollAnimations'
import { bindAll, qs, qsa, bounds } from '../../utils'
import { Emitter } from '../../events'

import { gsap } from 'gsap'

class Home extends Default {
  constructor(opt = {}) {
    super(opt)

    bindAll(this, 'onScroll')

    this.slug = 'body-home'

    setTimeout(() => {
      store.body.classList.add(this.slug)
      store.body.classList.remove('body-page')
      store.body.classList.remove('body-service')
      store.body.classList.remove('body-blog')
      store.body.classList.remove('body-event')
    }, 100)

    store.home = true

    this.once = false

    this.state = {
      current: -1,
      ready: false,
      outBounds: false,
    }

    this.state = {
      sticky: false,
    }
  }

  onEnter() {
    super.onEnter()
  }

  onEnterCompleted() {
    super.onEnterCompleted()
    this.addElements()
    this.addEvents()
    this.addGslider()
    this.getBounds()
    // this.addMobileScroll()

    this.sticky = new Sticky({
      el: qs('[data-sticky]'),
    })

    this.sticky.on()
  }

  addElements() {
    this.slides = qsa('[data-slide]')
    this.ones = qsa('[data-one]')
  }

  addEvents() {
    Emitter.on('pageScroll', this.onScroll)
    store.scroll.addOnePage()
  }

  removeEvents() {
    store.scroll.offPageScroll()
    Emitter.off('pageScroll', this.onScroll)
    Emitter.off('tick', this.run)
  }

  getBounds() {
    this.bounds = []
    this.sections = []

    this.slides.forEach((slide) => {
      const rect = bounds(slide)
      const obj = {
        el: slide,
        rect: rect,
      }
      this.bounds.push(obj)
    })

    this.ones.forEach((slide) => {
      const rect = bounds(slide)
      const obj = {
        el: slide,
        rect: rect,
      }
      this.sections.push(obj)
    })
  }

  run(e) {
    super.run(e)

    if (!this.state.ready || !store.sniff.isDevice) return

    this.scroll = e.current

    this.bounds.forEach((slide, i) => {
      const top = slide.rect.top - store.vh / 1.4
      const bottom = slide.rect.bottom - store.vh / 2
      const total = this.bounds.length - 1

      if (this.scroll > top && this.scroll < bottom) {
        const index = i

        if (
          index == this.state.current ||
          this.state.isAnimating ||
          this.isAnimating
        )
          return

        const data = slide.el.dataset.slide

        this.state.isAnimating = true
        this.removeModel()
        store.three.models.forEach((model) => {
          if (model.name == data) {
            model.add()
          }
        })
        this.state.current = index
        this.state.outBounds = false

        setTimeout(() => {
          this.state.isAnimating = false
        }, 1500)
      } else {
        if (
          i == this.bounds.length - 1 &&
          this.scroll > this.bounds[total].rect.bottom
        ) {
          if (this.state.outBounds || this.state.isAnimating) return
          this.removeModel()
          this.state.isAnimating = true
          this.state.outBounds = true
          this.state.current += 1

          setTimeout(() => {
            this.state.isAnimating = false
          }, 1500)
        }
      }
    })
  }

  addMobileScroll() {
    if (!store.sniff.isDevice) return

    const state = {
      posStart: 0,
      posEnd: 0,
      posCurrent: 0,
      count: 0,
      total: this.sections.length - 1,
    }
    store.body.style.overflow = 'hidden'

    const unify = (e) => {
      return e.changedTouches ? e.changedTouches[0] : e
    }

    const handleTouchStart = (e) => {
      state.start = true
      state.posStart = unify(e).pageY
    }

    const handleTouchEnd = (e) => {
      state.start = false
      state.posEnd = unify(e).pageY
    }

    const handleTouchMove = (e) => {
      if (state.animate || !state.start) return

      state.posCurrent = unify(e).pageY
      state.direction = state.posStart > state.posCurrent ? 'down' : 'up'

      const swipe = Math.abs(parseInt(state.posStart - state.posCurrent))

      if (swipe < 50) return

      state.animate = true

      if (state.direction == 'down') {
        state.count = state.count < state.total ? state.count + 1 : state.total
      } else if (state.direction == 'up') {
        state.count = state.count > 0 ? state.count - 1 : 0
      } else {
      }

      const slide = this.sections[state.count]
      const top = slide.rect.top

      store.raf.scrollTo(top, 1)

      setTimeout(() => {
        state.animate = false
      }, 1000)
    }

    document.addEventListener('touchstart', handleTouchStart, false)
    document.addEventListener('touchend', handleTouchEnd, false)
    document.addEventListener('touchmove', handleTouchMove, false)
  }

  removeModel() {
    store.three.models.forEach((model, i) => {
      if (model.isActive) model.remove()
    })
  }

  addGslider() {
    const el = qs('.insights-container')
    const container = qs('.insights')
    const links = qsa('[data-url]', container)

    document.documentElement.style.setProperty(
      '--sliderWidth',
      280 * (links.length + 1) + 'px',
    )

    var options = {
      container: container,
      element: el,
      links: links,
      easing: 0.075,
      duration: 100,
      dragSpeed: 1.75,
    }

    setTimeout(() => {
      this.slider = new GSlider(options)
    }, 500)
  }

  onScroll(e) {
    const current = e.current.el
    const prev = e.prev.el
    const chars = qsa('.char-1', current)
    const content = qs('.service-content', prev)
    const hero = qs('.hero-content', prev)
    const index = e.index
    const old = e.old
    const button = qs('.button', current)
    const direction = index > old ? 'down' : 'up'
    const play = e.tl
    const description = qs('.hero-description', current)
    const scroll = qs('.scroll', current)
    const sticky = qs('.section-footer .footer-content')
    const services = qs('.services-footer', current)
    const nrs = qsa('.service-nr .p1', sticky)
    const tag = qsa('.service-tag .p1', sticky)
    const excerpt = qsa('.service-excerpt .p2', sticky)
    const posts = qsa('.insight-post', current)

    const form = qs('.form-item', current)
    const label = form ? qs('.p1', form) : null
    const input = form ? qsa('input', form) : null
    const line = form ? qs('.line', form) : null

    const sitemap = qs('.footer-sitemap', current)
    const sitemapItems = sitemap ? qsa('.p1', sitemap) : null
    const copyright = qs('.footer-copyright', current)
    const copyrightItems = copyright ? qsa('.p1', copyright) : null

    if (!play) return

    const slide = current.dataset.slide

    const tl = gsap.timeline({
      onComplete: () => {
        if (description) gsap.set(description, { clearProps: 'all' })
        if (scroll) gsap.set(scroll, { clearProps: 'all' })
      },
    })

    if (index <= store.three.models.length) {
      this.removeModel()

      store.three.models.forEach((model) => {
        if (model.name == slide) model.add()
      })
    }

    // Animate Out

    if (content)
      tl.to(
        content,
        {
          duration: 0.6,
          autoAlpha: 0,
          ease: 'power3.out',
          onComplete: () => {
            gsap.set(content, { clearProps: 'all' })
          },
        },
        0,
      )
    if (hero)
      tl.to(
        hero,
        {
          duration: 0.6,
          autoAlpha: 0,
          ease: 'power3.out',
          onComplete: () => {
            gsap.set(hero, { clearProps: 'all' })
          },
        },
        0,
      )

    if (!services && this.state.sticky) {
      if (direction == 'up') {
        tl.to(sticky, { y: '101%', duration: 0.5 }, 0)
      } else {
        tl.to(sticky, { autoAlpha: 0, duration: 0.5 }, 0)
      }
      this.state.sticky = false
    }

    // Animate In

    const getter = gsap.getProperty(chars[0])
    const y = getter ? getter('y', 'px') : 0
    let delay = 0
    if (direction == 'up' && index == this.slides.length - 1) delay = 0.5

    if (chars.length !== 0)
      tl.fromTo(
        chars,
        {
          y: '110%',
        },
        {
          duration: 1,
          y: y,
          delay: delay,
          stagger: 0.05,
          ease: 'power3.inOut',
        },
      )
    if (button)
      tl.fromTo(
        button,
        { autoAlpha: 0, y: 40 },
        { duration: 0.6, autoAlpha: 1, y: 0, ease: 'power2.out' },
        '-=.5',
      )
    if (description)
      tl.fromTo(
        description,
        { autoAlpha: 0, y: '40' },
        { duration: 0.5, autoAlpha: 1, y: '0' },
        '-=0.8',
      )
    if (scroll)
      tl.fromTo(
        scroll,
        { autoAlpha: 0, y: '20' },
        { duration: 0.5, autoAlpha: 1, y: '0' },
        '-=0.5',
      )

    if (services && !this.state.sticky) {
      tl.fromTo(
        sticky,
        { y: '101%', autoAlpha: 1 },
        { y: '0%', duration: 0.5, delay: 0.5 },
        0,
      )
      this.state.sticky = true
    }

    if (posts.length !== 0) {
      tl.from(
        posts,
        {
          duration: 1.4,
          x: '80%',
          stagger: 0.12,
          autoAlpha: 0,
          ease: 'power3.out',
          onComplete: () => {
            gsap.set(posts, { clearProps: 'all' })
          },
        },
        '-=0.9',
      )
    }

    if (services) {
      let all = []
      if (old !== 0 && old < e.total - 1) all = qsa('.line-0', excerpt[old - 1])
      const lines = qsa('.line-0', excerpt[index - 1])

      tl.to(nrs, { duration: 0.4, y: '-101%', delay: 0.6 }, 0)
      tl.fromTo(
        nrs[index - 1],
        { y: '101%', autoAlpha: 0 },
        { duration: 0.4, y: '0%', autoAlpha: 1, delay: 0.8 },
        0,
      )

      tl.to(tag, { duration: 0.4, y: '-101%', delay: 0.7 }, 0)
      tl.fromTo(
        tag[index - 1],
        { y: '101%', autoAlpha: 0 },
        { duration: 0.4, y: '0%', autoAlpha: 1, delay: 0.9 },
        0,
      )

      if (all.length !== 0)
        tl.to(all, { duration: 0.4, y: '-101%', delay: 0.8, stagger: 0.12 }, 0)
      if (lines.length !== 0)
        tl.fromTo(
          lines,
          { y: '101%', autoAlpha: 0 },
          { duration: 0.4, y: '0%', autoAlpha: 1, stagger: 0.12, delay: 1 },
          0,
        )
    }

    if (form) {
      tl.fromTo(
        label,
        { autoAlpha: 0, y: 20 },
        { duration: 0.5, autoAlpha: 1, y: 0, delay: 0.5 },
      )
      tl.fromTo(
        input,
        { autoAlpha: 0, y: 20 },
        { duration: 0.5, autoAlpha: 1, y: 0, stagger: 0.1 },
        '-=0.4',
      )
      tl.fromTo(
        line,
        { scaleX: 0 },
        {
          duration: 0.5,
          scaleX: 1,
          transformOrigin: 'left',
          ease: 'power3.inOut',
        },
        '-=1',
      )

      tl.fromTo(
        sitemapItems,
        { autoAlpha: 0, y: 20 },
        {
          duration: 0.8,
          y: 0,
          autoAlpha: 1,
          stagger: 0.05,
          onComplete: () => {
            gsap.set(sitemapItems, { clearProps: 'all' })
          },
        },
        '-=0.5',
      )
      tl.fromTo(
        copyrightItems,
        { autoAlpha: 0, y: 20 },
        {
          duration: 0.8,
          y: 0,
          autoAlpha: 1,
          stagger: 0.1,
          onComplete: () => {
            gsap.set(copyrightItems, { clearProps: 'all' })
          },
        },
        '-=0.8',
      )
    }
  }

  animateIn() {
    super.animateIn()

    if (!store.loaded || !store.particlesReady) return

    const homeIn = HomeIn()

    this.state.ready = true

    store.three.models.forEach((model, i) => {
      if (model.name == 'world') {
        if (store.sniff.isDevice) return
        model.add()
      }
    })

    setTimeout(() => {
      homeIn.play()
      this.animateOnScroll()
    }, 300)
  }

  animateOnScroll() {
    if (!store.sniff.isDevice) return

    const services = qs('.services')
    const obj = {}

    obj.el = services
    obj.service = qsa('.service', services)
    obj.slides = qsa('[data-slide]')

    obj.service.forEach((service) => {
      const chars = qsa('.char-1', service)
      const button = qs('.service-content .button', service)
      const button2 = qs('.services-footer .button', service)
      const sticky = qs('.services-footer', service)
      const nrs = qs('.service-nr .p1', service)
      const excerpt = qsa('.service-excerpt .line-0', service)

      const getter = gsap.getProperty(chars[0])
      const y = getter ? getter('y', 'px') : 0

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: service,
          start: 'top 55%',
        },
      })

      tl.fromTo(
        chars,
        {
          y: '101%',
        },
        {
          duration: 1,
          y: y,
          stagger: 0.05,
          ease: 'power3.inOut',
        },
      )

      if (button) {
        tl.fromTo(
          button,
          { autoAlpha: 0, y: 40 },
          { duration: 0.6, autoAlpha: 1, y: 0, ease: 'power2.out' },
          '-=.5',
        )
      }

      // tl.from(sticky, { autoAlpha: 0, duration: 0.5 })

      tl.fromTo(
        nrs,
        { y: '101%', autoAlpha: 0 },
        { duration: 0.4, y: '0%', autoAlpha: 1 },
        '-=0.4',
      )

      tl.fromTo(
        excerpt,
        { y: '101%', autoAlpha: 0 },
        { duration: 0.4, y: '0%', autoAlpha: 1, stagger: 0.12 },
        '-=0.2',
      )

      if (button2) {
        tl.fromTo(
          button2,
          { autoAlpha: 0, y: 40 },
          { duration: 0.6, autoAlpha: 1, y: 0, ease: 'power2.out' },
          '-=.3',
        )
      }
    })

    footerScroll()
  }

  onResize() {
    super.onResize()
  }

  onLeave() {
    super.onLeave()
    this.slider && this.slider.destroy()
    this.isAnimating = true
  }

  onLeaveCompleted() {
    super.onLeaveCompleted()
    this.removeEvents()
  }
}

export default Home
