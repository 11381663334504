import store from '../store'

import VirtualScroll from '../vendor/virtual-scroll'
import Emitter from './Emitter'
import { qsa, bindAll, bounds, qs } from '../utils'
import { Sniffer } from '../utils/Sniffer'
import { gsap } from 'gsap'

const lethargy = require('lethargy').Lethargy

export default class Scroll {
  constructor(o) {
    this.smooth = o.smooth || false
    store.flags.smooth = this.smooth

    bindAll(this, 'onEvent', 'onScroll', 'sectionScroll', 'onResize')

    this.inertia = new lethargy(10, 50)
    this.isOnePage = false

    this.onePage = {
      on: false,
      next: 0,
      prev: 0,
      delta: 1,
      animating: false,
      index: 0,
      total: 0,
    }
  }

  setScrollBounds() {
    store.bounds = {}
    const height = bounds(store.page).height
    store.bounds.scroll = height > store.vh ? height - store.vh : 0
  }

  onEvent(e) {
    if (store.flags.locked) return

    if (this.isOnePage) {
      this.sectionScroll(e)
    } else {
      Emitter.emit('scroll', { y: e.deltaY * -1 })
    }
  }

  onScroll(e) {
    if (store.flags.locked) return

    if (this.isOnePage) {
      this.sectionScroll(e)
    } else {
      Emitter.emit('scroll', { y: window.scrollY })
    }
  }

  addOnePage() {
    if (store.sniff.isDevice) return
    this.isHome = qs('.is-home')
    this.onPageScroll()
  }

  onPageScroll() {
    if (!this.isHome) return

    store.body.style.overflow = 'hidden'
    this.isOnePage = true
    this.data = []
    this.sections = qsa('[data-one]')

    this.sections.forEach((section, i) => {
      const rect = bounds(section)

      const obj = {
        el: section,
        rect: rect,
        index: i,
      }
      this.data.push(obj)
    })

    this.onePage.total = this.data.length - 1
  }

  offPageScroll() {
    store.body.style.overflow = ''
    this.isOnePage = false
    this.onePage.index = 0
    this.onePage.next = 0
  }

  sectionScroll(e) {
    if (this.resize) return

    const { total, next, index } = this.onePage
    const originalEvent = e.originalEvent || e
    const val = this.inertia.check(originalEvent)
    const rounded = Math.floor(store.raf.current)

    if (val === false || this.onePage.animating) return

    this.onePage.animating = true

    const direction = val > 0 ? 'up' : 'down'

    if (rounded < 0 && direction == 'up') return

    this.onePage.prev = this.onePage.next

    this.onePage.next =
      direction == 'up'
        ? next <= 0
          ? 0
          : next - 1
        : next < total
        ? next + 1
        : total

    const current = this.data[this.onePage.next]
    const prev = this.data[this.onePage.prev]
    const top = current.rect.top
    const height = current.rect.height
    this.onePage.index = current.index
    const time = next == this.onePage.next ? 0 : 1500

    Emitter.emit('pageScroll', {
      index: this.onePage.next,
      old: this.onePage.prev,
      total: this.onePage.total,
      current: current,
      prev: prev,
      tl: time == 0 ? false : true,
    })

    if (
      (next < total - 2 && direction == 'down') ||
      (next < total - 1 && direction == 'up')
    ) {
      setTimeout(() => {
        store.raf.setScroll(top)
      }, 500)
    } else {
      setTimeout(() => {
        store.raf.scrollTo(top, 0.8)
      }, 0)
    }

    setTimeout(() => {
      this.onePage.animating = false
    }, time)
  }

  toCloser() {
    if (!this.isHome) return

    const current = this.data[this.onePage.next]
    const top = current.rect.top
    const height = current.rect.height
    this.onePage.index = current.index

    if (height !== store.vh) {
      store.raf.scrollTo(top + height - store.vh, 0.3)
    } else {
      store.raf.scrollTo(top, 0.3)
    }
  }

  on() {
    this.l('add')
    Emitter.on('smooth:resize', this.onResize)
  }

  off() {
    this.l('remove')
  }

  l(a) {
    if (this.smooth) {
      store.body.classList.add('is-smooth')

      const action = a === 'add' ? 'on' : 'off'

      store.vs = new VirtualScroll({
        mouseMultiplier: Sniffer.sniff.isWindows ? 1.1 : 0.45,
        touchMultiplier: 3.5,
        firefoxMultiplier: Sniffer.sniff.isWindows ? 40 : 90,
        passive: true,
      })

      store.vs[action](this.onEvent)
    } else {
      window.addEventListener('scroll', this.onScroll)
    }
  }

  onResize() {
    this.resize = true
    this.data = null
    this.addOnePage()
    this.toCloser()
    this.resize = false
  }
}
