import store from '../store'
import Highway from '@dogstudio/highway'
import Home from './renders/home'
import Page from './renders/page'
import Service from './renders/service'
import Post from './renders/post'
import Event from './renders/event'
import Fade from './transitions/fade'
import ServiceOut from './transitions/serviceOut'
import ThreeSetup from '../components/ThreeSetup'

import { qs, qsa, bindAll } from '../utils'
import { gsap } from 'gsap'
import { Emitter } from '../events'

export default class HController {
  constructor() {
    bindAll(
      this,
      'nav',
      'openMenu',
      'closeMenu',
      'mouseIn',
      'mouseOut',
      'run',
      'onResize',
    )

    this.three = new ThreeSetup()

    store.H = new Highway.Core({
      renderers: {
        page: Page,
        home: Home,
        service: Service,
        blog: Post,
        event: Event,
      },
      transitions: {
        default: Fade,
        contextual: {
          service: ServiceOut,
        },
      },
    })

    const options = { hour: '2-digit', timeZone: 'America/New_York' }
    const NY = new Date().toLocaleDateString('en-US', options)
    const daynight = NY.substr(NY.length - 2)

    if (daynight == 'AM') {
      store.body.classList.add('-light')
      store.body.classList.remove('-dark')
      store.mode = 'light'
    } else {
      store.body.classList.add('-dark')
      store.body.classList.remove('-light')
      store.mode = 'dark'
    }

    this.state = {
      menu: false,
    }

    this.init()
  }

  els() {
    const header = qs('header')
    this.menu = qs('.menu')
    this.open = qs('.-open', header)
    this.close = qs('.-close', this.menu)
    this.links = qsa('.menu .menu-link')
    this.switch = qs('.switch')
  }

  l() {
    store.H.on('NAVIGATE_IN', this.nav)
    store.H.on('NAVIGATE_END', ({ from, to, location }) => {
      if (typeof gtag !== 'undefined') {
        gtag('config', 'G-C33TM8WTWF', {
          page_path: location.pathname,
          page_title: to.page.title,
          page_location: location.href,
        })
      }
    })

    this.open.addEventListener('click', this.openMenu)
    this.close.addEventListener('click', this.closeMenu)
    this.links.forEach((link) => {
      link.addEventListener('mouseenter', this.mouseIn)
      link.addEventListener('mouseleave', this.mouseOut)
    })

    this.switch.addEventListener('click', this.toogleSwitch)
    document.addEventListener('keydown', (e) => {
      if (e.code == 'Escape') this.closeMenu()
    })

    //Emitter.on('tick', this.run)
    Emitter.on('GlobalResize', this.onResize)
  }

  nav({ to, location, trigger }) {
    this.closeMenu(location)
  }

  toogleSwitch() {
    const dark = store.body.classList.contains('-dark')
    const noob = qs('.switch-inner')

    if (dark) {
      store.body.classList.remove('-dark')
      store.body.classList.add('-light')
      store.mode = 'light'
    } else {
      store.body.classList.add('-dark')
      store.body.classList.remove('-light')
      store.mode = 'dark'
    }
  }

  openMenu() {
    //gsap.to(this.menuOverlay, {duration: 0.5, autoAlpha: 1})
    const tl = gsap.timeline()
    gsap.to(this.menu, {
      duration: 0.5,
      autoAlpha: 1,
      ease: 'power3.out',
      onComplete: () => {
        this.state.menu = true

        store.openMenu = true
      },
    })
  }

  closeMenu(url) {
    if (!this.state.menu) return

    gsap.to(this.menu, { duration: 0.5, autoAlpha: 0, ease: 'power3.out' })

    const tl = gsap.timeline({
      onComplete: () => {
        this.updateMenu(url)
        this.state.menu = false
        store.openMenu = false
      },
    })
  }

  updateMenu(url) {
    const location = url ? url : window.location

    this.links.forEach((link) => {
      link.parentNode.classList.remove('-active')
      if (link.href === location.href) {
        link.parentNode.classList.add('-active')
      }
    })
  }

  mouseIn(el) {
    const target = el.target

    this.links.forEach((link) => {
      const parent = link.parentNode

      target.parentNode.classList.add('-current')

      if (link !== target) {
        parent.classList.add('-hover')
      }
    })
  }

  mouseOut(el) {
    const target = el.target
    target.parentNode.classList.remove('-current')
    this.links.forEach((link) => {
      const parent = link.parentNode
      parent.classList.remove('-hover')
    })
  }

  onResize() {
    this.three.onResize()
  }

  init() {
    this.els()
    this.l()
  }
}
