import { Sniffer, qs, getViewport, getWindowSizes } from './utils'

export default {
  title: 'PerimeterWatch',
  author: 'Non-Linear',
  link: 'https://twitter.com/linear_non',
  body: document.body,
  main: qs('main'),
  header: qs('header'),
  page: qs('.page'),
  footer: qs('footer'),
  transition: qs('.page-transition'),
  vw: getViewport().width,
  vh: getViewport().height,
  sniff: Sniffer.sniff,
  sizes: getWindowSizes(),
  preloader: true,

  flags: {
    smooth: true,
    locked: true,
    dropdown: false,
    resize: false,
  },
}
