import store from '../../store'
import Default from './default'
import { bindAll, qs, qsa, getid, bounds } from '../../utils'
import { BlogIn } from '../../components/AnimateIn'
import {
  postoneScroll,
  posttwoScroll,
  postlistScroll,
  blogcontentScroll,
  relatedScroll,
  formScroll,
  footerScroll,
} from '../../components/ScrollAnimations'
import SmoothScroll from '../../components/SmoothScroll'

class Event extends Default {
  constructor(opt = {}) {
    super(opt)

    bindAll(this, 'scrollTo', 'filterPosts')

    this.slug = 'body-event'

    store.body.classList.add(this.slug)
    store.body.classList.remove('body-home')
    store.body.classList.remove('body-service')
    store.body.classList.remove('body-page')
    store.body.classList.remove('body-post')
  }

  onEnter() {
    super.onEnter()
  }

  onEnterCompleted() {
    super.onEnterCompleted()
    this.addElements()
    this.addEvents()
    this.scrollToSections()
  }

  addElements() {
    this.isEvent = qs('.is-event')
    this.categories = qsa('.post-topics .topic-item')
    this.originalPosts = qsa('.post-list .post-content')
  }

  addEvents() {
    if (this.categories.length !== 0) {
      this.categories.forEach((el) => {
        el.addEventListener('click', this.filterPosts)
      })
    }
  }

  removeEvents() {}

  filterPosts(e) {
    const target = e.target
    const data = target.dataset.topic
    const container = qs('.post-list')
    const current = []

    this.categories.forEach((el) => el.classList.remove('-active'))

    target.classList.add('-active')

    container.innerHTML = ''

    this.originalPosts.forEach((post) => {
      const topics = post.dataset.topics
      const parsed = topics.split(',')

      parsed.forEach((el) => {
        if (el == data) {
          current.push(post)
        }
      })
    })

    current.forEach((el) => {
      container.innerHTML += el.outerHTML
    })

    SmoothScroll.update()
  }

  scrollToSections() {
    this.targets = []
    this.linkTo = qsa('[data-link]')

    this.linkTo.forEach((link) => {
      const href = link.href
      const hash = href.split('#')[1]
      const el = getid(`${hash}`)
      const b = bounds(el)

      const obj = {
        el: el,
        href: href,
        hash: hash,
        top: b.top,
      }

      this.targets.push(obj)
      link.addEventListener('click', this.scrollTo)
    })
  }

  scrollTo(e) {
    e.preventDefault()
    const target = e.target
    const href = target.href

    this.targets.forEach((el) => {
      if (el.href == href) store.raf.scrollTo(el.top)
    })
  }

  animateIn() {
    super.animateIn()

    if (!store.loaded || !store.particlesReady) return

    const blogIn = BlogIn()

    if (this.isEvent) {
      const event = store.page.dataset.name

      store.three.models.forEach((model) => {
        if (model.name == event) {
          model.add()
        }
      })
    }

    setTimeout(() => {
      blogIn.play()
      this.animateOnScroll()
    }, 300)
  }

  animateOnScroll() {
    postoneScroll()
    posttwoScroll()
    blogcontentScroll()
    postlistScroll()
    relatedScroll()
    formScroll()
    footerScroll()
  }

  onLeave() {
    super.onLeave()
    this.removeEvents()
  }

  onLeaveCompleted() {
    super.onLeaveCompleted()
  }
}

export default Event
