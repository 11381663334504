import store from './store'
import HController from './highway/HController'
import FontFaceObserver from 'fontfaceobserver'
import { Resize, RafController, Scroll, Mouse, Emitter } from './events'
import { Sniffer } from './utils'
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'lazy'
lazySizes.cfg.loadedClass = 'loaded'

export default class App {
  constructor() {
    console.log(
      `%c${store.title} \nMade with ❤️ by ${store.author} \n↳ https://twitter.com/linear_non`,
      'color: #6a6a6a',
    )

    const font = new FontFaceObserver('Telegraf', 10000)

    store.highway = new HController()
    store.raf = new RafController()
    store.mouse = new Mouse()
    store.scroll = new Scroll({
      smooth: Sniffer.sniff.isDesktop,
    })

    store.resize = new Resize()

    store.resize.on()

    font
      .load()
      .then((_) => {
        this.onload()
      })
      .catch((_) => {
        this.onload()
      })

    store.raf.on()
    store.raf.setScrollTrigger()
    store.scroll.on()
    store.mouse.on()
  }

  onload() {
    store.resize.onResize()
    store.loaded = true
    Emitter.emit('fontLoaded')
  }

  init() {}
}
